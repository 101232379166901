import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {IResponse} from "../models/response.models";
import { ISettingSite, ISupportDepart } from "../models/setting.models";

@Injectable({
  providedIn: 'root'
})
export class SystemSettingService {
  private apiUrl = environment.apiUrl + '/system_settings/';


  constructor(
    private http: HttpClient
  ) {}

  getSettingSite(): Observable<IResponse<ISettingSite>> {
    return this.http.get<IResponse<ISettingSite>>(this.apiUrl + 'website/site')
  }

  getSupportDepart(): Observable<IResponse<ISupportDepart>> {
    return this.http.get<IResponse<ISupportDepart>>(this.apiUrl + 'support_depart')
  }
}
