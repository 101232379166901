import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {CommonModule} from "@angular/common";
import {UiIconComponent} from "../ui-icon/ui-icon.component";

@Component({
  selector: 'ui-link',
  templateUrl: './ui-link.component.html',
  styleUrls: ['./ui-link.component.scss'],
  standalone: true,
  imports: [CommonModule, UiIconComponent]
})
export class UiLinkComponent implements OnChanges, AfterViewInit {
  @Input() size: 'medium' | 'base' | 'small' | string = 'medium';
  @Input() color: string = 'makktina-2-600';
  @Input() leftIcon!: string;
  @Input() leftIconType: 'solid' | 'outline' | 'shared' | 'mini' = 'mini';
  @Input() rightIcon!: string;
  @Input() rightIconType: 'solid' | 'outline' | 'shared' | 'mini' = 'mini';
  @ViewChild('elemRef', {static: false}) elemRef: ElementRef;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setColor();
  }

  ngAfterViewInit(): void {
    this.setColor();
  }
  setColor() {
    if (this.color && this.elemRef) {
      this.elemRef.nativeElement.style.color = `var(--${this.color})`
    }
  }

}
