import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {IResponse} from "../../models/response.models";

@Injectable({
  providedIn: 'root'
})
export class ClientAuthService {
  private apiUrl = environment.host + environment.apiVersion + '/identities';

  constructor(private http: HttpClient) {
  }

  handShake(deviceInfo: any): Observable<IResponse<{ accessToken: string, refreshToken: string }>> {
    return this.http.post<IResponse<{ accessToken: string, refreshToken: string }>>(this.apiUrl + '/hand_shake', deviceInfo)
  }

  registration(params: { username: string, password: string, confirmPassword: string }): Observable<IResponse<{ accessToken: string, refreshToken: string }>> {
    return this.http.post<IResponse<{ accessToken: string, refreshToken: string }>>(this.apiUrl + '/registration', params)
  }

  authentication(params: {email: string, password: string}): Observable<IResponse<{ accessToken: string, refreshToken: string }>> {
    return this.http.post<IResponse<{ accessToken: string, refreshToken: string }>>(this.apiUrl + '/authentication', params)
  }

  login(temporaryToken: string): Observable<IResponse<string>> {
    const rememberMe = JSON.parse(localStorage.getItem('savePassword') ?? 'false')
    return this.http.post<IResponse<string>>(this.apiUrl + '/login', {rememberMe}, {
      headers: {
        'Authorization': temporaryToken
      }
    })
  }

  reset(email: string): Observable<IResponse<string>> {
    return this.http.post<IResponse<string>>(this.apiUrl + '/reset', {email})
  }

  resetConfirm(params: { password: string, confirmPassword: string }, token: string): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/reset/confirm', params,
      {
        headers: {
          Authorization: token
        }
      })

  }

  refresh(params: {accessToken: string, refreshToken: string}): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/refresh', params);
  }

  logout(): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/logout', {})
  }
}
