import {Component, OnInit} from '@angular/core';
import {take} from "rxjs";
import {SystemSettingService} from "./core/services/system-setting.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mk';

  constructor(
    private _service: SystemSettingService,
    private _title: Title,
    private _meta: Meta
  ) {
  }

  ngOnInit() {
    this._getSettings();
  }



  private _getSettings() {
    this._service.getSettingSite()
      .pipe(take(1))
      .subscribe(res => {
        this._title.setTitle(res.data.name);
        this._meta.addTag({name: 'description', content: res.data.description} );
        this._meta.addTag({name: 'keywords', content: res.data.keywords} );
      })
  }
}
